import React, { useContext } from "react"
import Headroom from "react-headroom"
import PrimaryNav from "./PrimaryNav"
import isArray from "lodash/isArray"
import Logo from "./Logo"
import Wrapper from "./Wrapper"
import Navicon from "./Navicon"
import StoreContext from "../context/StoreContext"

const Header = props => {
  const { primaryNav, lang, altLang } = props
  const context = useContext(StoreContext)
  const { pushNavVisible } = context
  return (
    <Headroom>
      <header className={"header " + (pushNavVisible ? "co--darkest" : "")}>
        <Wrapper>
          <div className="inner">
            <Navicon />
            <Logo lang={lang} />
            {isArray(primaryNav) && <PrimaryNav links={primaryNav} lang={lang} altLang={altLang} />}
          </div>
        </Wrapper>
      </header>
    </Headroom>
  )
}

export default Header
