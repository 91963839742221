import React from "react"
import SecondaryNav from "./SecondaryNav"
import Logo from "./Logo"
import Wrapper from "./Wrapper"
import Grid from "./Grid"
import ExternalLink from "./ExternalLink"
import get from "lodash/get"
import isArray from "lodash/isArray"
import { RichText } from "prismic-reactjs"
import { defaultLang } from "../../prismic-configuration"
import getTranslations from "../translations"

const Footer = props => {
  const { secondaryNav, address, vat, lang } = props
  const t = getTranslations(lang)
  const addressString = get(address, "[0].text")
  return (
    <footer className="slice footer">
      <Wrapper>
        <Grid>
          <Logo lang={lang} />
          {isArray(secondaryNav) && <SecondaryNav links={secondaryNav} lang={lang} />}
          <div className="co--light">
            {address && addressString && (
              <>
                <ExternalLink
                  title={t.footer.google_maps}
                  to={`https://www.google.com/maps/place/${addressString}`}
                >
                  <address className="lh--m">
                    {RichText.render(address)}
                  </address>
                </ExternalLink>
              </>
            )}
            {vat && <p className="lh--m">CVR: {vat}</p>}
            <p className="lh--m">{t.footer.contact_us}</p>
            <p className="lh--m">{lang === defaultLang && '60514919'} {t.footer.workdays}</p>
            {defaultLang === lang && (
              <p className="lh--m">
                <a href="mailto:hi@anddrape.com">hi@anddrape.com</a>
              </p>
            )}
          </div>
        </Grid>
      </Wrapper>
    </footer>
  )
}

export default Footer
