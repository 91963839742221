import React from 'react'

const Wrapper = (props) => {
  return(
    <div className="wrapper">
      { props.children }
    </div>
  )
}

export default Wrapper