import React, {useContext} from 'react'
import { Link } from "gatsby"
import { linkResolver } from '../prismic/linkResolver'
import StoreContext from '../context/StoreContext'

const InternalLink = (props) =>{
  const {title, children, classList} = props
  const context = useContext(StoreContext)
  const {setPushNavVisibility} = context

  const handleOnClick = (event) =>{
    setPushNavVisibility(false)
  }

  return <Link
    title={title}
    to={ linkResolver(props) }
    className={classList}
  onClick={handleOnClick}>
    { children }
  </Link>
}

export default InternalLink