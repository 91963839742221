import React from 'react'
import Img from './CustomImg'

const ProductImage = (props) => {
  const {title, src} = props
  return(
    <div className="product-image-wrapper">
      <div className="product-image-border"></div>
      {Array.isArray(src) ?
        <Img className="product-image" src={src} title={title} />
      :
        <img src={src} alt={title}/>
      }
    </div>
  )
}

export default ProductImage