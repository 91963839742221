import React from 'react'
import onClickOutside from "react-onclickoutside";

import CloseSVG from '../images/close.inline.svg'
import Card from './Card'

const ModalContent = (props) => {
  const {title, text, handleClose} = props
  ModalContent.handleClickOutside = () => handleClose();
  return(
    <Card>
      {title &&
        <header className="modal-header mb--s">
          <h4 className="fs--m">{title}</h4>
          <div onClick={()=>{handleClose()}}>
            <CloseSVG />
          </div>
        </header>
      }
      {text &&
        <div className="co--light lh--m article">
          {text}
        </div>
        }
    </Card>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => ModalContent.handleClickOutside
};

export default onClickOutside(ModalContent, clickOutsideConfig);