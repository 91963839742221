/**
 * This file is used for setting up and connecting to Prismic.
 */

module.exports = {
  // The default language for content in your Prismic repository.
  defaultLang: 'da-dk',

  // All available languages for content in your Prismic repository.
  langs: ['da-dk', 'en-eu'],
}