import React from 'react'

const AspectRatio = (props) => {
  return(
    <div className="aspect-ratio" data-ratio={props.ratio}>
      <div className="aspect-ratio-inner">
        {props.children}
      </div>
    </div>
  )
}

export default AspectRatio