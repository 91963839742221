import React from 'react'
import InternalLink from './InternalLink'
import get from 'lodash/get'

const TertiaryNav = (props) => {
  let { links } = props

  return(
    <nav className="tertiary-nav">
      <ul>
        { links.map((node, i) => {
          const link = get(node, 'tertiary_link')
          const title = get(link, 'title[0].text')
          const uid = get(link, '_meta.uid')
          const type = get(link, '_meta.type')
          const lang = get(link, '_meta.lang')

          if(uid && type){
            return <li key={i}><InternalLink uid={uid} type={type} title={title} lang={lang}>{title}</InternalLink></li>
          }
        }) }
      </ul>
    </nav>
  )
}

export default TertiaryNav