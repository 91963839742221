import React from 'react'
import Grid from './Grid'
import Wrapper from './Wrapper'
import TertiaryNav from './TertiaryNav'
import PaymentOptions from './PaymentOptions'
import isArray from 'lodash/isArray'
import LanguageSwitcher from './LanguageSwitcher'

const ShopFooter = (props) =>{
  const { tertiaryNav, paymentOptions, lang, altLang, currentPath } = props

  return(
    <div className="slice shop-footer">
      <Wrapper>
        <div className='terinary-footer'>
          { isArray(paymentOptions) &&
            <PaymentOptions links={paymentOptions} />
          }
          { isArray(tertiaryNav) &&
            <TertiaryNav links={tertiaryNav}/>
          }
          <LanguageSwitcher currentLang={lang} altLang={altLang} />
        </div>
      </Wrapper>
    </div>
  )
}

export default ShopFooter