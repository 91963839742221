import React from "react"
import { Link } from "gatsby"
import { linkResolver } from "../prismic/linkResolver";
import { defaultLang } from "../../prismic-configuration";

const LanguageSwitcher = ({ altLang, currentLang }) => {
  // If altLang doesn't exist, set it to homepage
  altLang = altLang?.length ? altLang[0] : { lang: ( currentLang === defaultLang ? "en-eu" : defaultLang ), type: "homepage" }

  // Determine paths based on alternative language
  const pathDa = altLang.lang === "en-eu" ? null : linkResolver(altLang);
  const pathEu = altLang.lang === "en-eu" ? linkResolver(altLang) : null;

  return (
    <div className="lang-switcher__container">
      {pathDa ? (
        <Link className="lang-switcher__link" to={`/${pathDa}`}>da</Link>
      ) : <span className="lang-switcher__current">da</span>} / {" "}
      {pathEu ? (
        <Link className="lang-switcher__link" to={`/${pathEu}`}>en</Link>
      ) : <span className="lang-switcher__current">en</span>}
    </div>
  )
}

export default LanguageSwitcher
