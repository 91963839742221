import React, {useState,useEffect} from 'react'
import axios from 'axios'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import orderBy from 'lodash/orderBy'
import Wrapper from './Wrapper'
import Grid from './Grid'
import Button from './Button'
import ExternalLink from './ExternalLink'
import Img from '../components/CustomImg'
import AspectRatio from '../components/AspectRatio'
import TeaserTextOverlay from './TeaserTextOverlay'
import getTranslations from '../translations'

const Instafeed = (props) => {
  let { instagramCTA, instagramHandle, lang } = props
  const t = getTranslations(lang)

  if(isArray(instagramCTA)){
    instagramCTA = get(instagramCTA, '[0].text')
  }
  if(isArray(instagramHandle)){
    instagramHandle = get(instagramHandle, '[0].text')
  }

  const [images, setImages] = useState([]);

  const consumeData = (data) => {
    data.forEach(element => {
      setImages(images => [...images, element]);
    })
  }

  async function getDataAxios(){
    const response =
      await axios.get("https://graph.facebook.com/v5.0/17841407218707855/media?fields=media_url,permalink,caption&limit=4&access_token=EAAlLu8FWfuYBAEtSWPAczAl7QrdffZBoxofD97ovPsLtPPQwnRB23eZA8w08zRbIpH3EnXeNgroquOjM8U1KoHCJuneUOMpGaJ9TQbsPxxUIRoHMn9ePW015UolEC8ZAR4boXb4udYp2U9uDy3GJhKIyo2JChC3IhmzOYJO5gZDZD")
    consumeData(response.data.data)
  }

  // useEffect(() => {
  //   getDataAxios()
  // }, [getDataAxios]);

  useEffect(() => {
    getDataAxios()
  },[]);


  return(
    <div className="slice instafeed">
      <Wrapper>
        <Grid>
          <div className="text">
            {instagramCTA &&
              <h4 className="fs--m lh--s">{instagramCTA}</h4>
            }
            <nav>
              {instagramHandle &&
                <ExternalLink to={`https://www.instagram.com/${instagramHandle}`} target="_blank" title={`@${instagramHandle}`}>
                  <Button text={`${t.instagramfeed.follow} @${instagramHandle}`} icon="chevron-right" />
                </ExternalLink>
              }
            </nav>
          </div>
          <div className="feed">
            <Grid>
              {images.map((image, index)=>{
                return(<a className="teaser" href={image.permalink} title={image.caption} target="_blank" key={index} rel="noopener noreferrer">
                  <div className="teaser-image-wrap">
                <AspectRatio ratio="square">
                  <Img src={image.media_url} alt={image.caption} />
                </AspectRatio>
                <TeaserTextOverlay heading={image.caption} cta="Se post" extraSmallText={true} />
                </div>
                </a>)
              })}
            </Grid>
          </div>
        </Grid>
      </Wrapper>
    </div>
  )
}

export default Instafeed