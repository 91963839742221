import React, { useContext, useEffect, useState } from "react"
import onClickOutside from "react-onclickoutside"
import StoreContext from "../context/StoreContext"
import countQuantity from "../utils/countQuantity"
import LineItem from "./LineItem"
import Button from "./Button"
import CloseSVG from "../images/close.inline.svg"
import getTranslations from "../translations"

const Basket = props => {
  const { lang } = props
  const context = useContext(StoreContext)
  const {
    checkout,
    setBasketVisibility,
    setOverlayVisibility,
    client,
    addOrderNote
  } = context
  const t = getTranslations(lang)
  const quantity = countQuantity(checkout.lineItems)

  const [orderNote, setOrderNote] = useState(checkout.note ? checkout.note : "")

  const handleClose = () => {
    setBasketVisibility(false)
  }

  Basket.handleClickOutside = () => handleClose()

  const line_items = checkout.lineItems.map(line_item => {
    return <LineItem key={line_item.id.toString()} line_item={line_item} />
  })

  const handleCheckout = () => {
    if (checkout.lineItems.length > 0) {
      let checkoutUrl = checkout.webUrl
      if (window.ga) {
        if (typeof window.ga.getAll === "function") {
          checkoutUrl =
            checkoutUrl + "&" + window.ga.getAll()[0].get("linkerParam")
        }
      }
      window.location.href = checkoutUrl
    }
  }

  const handleKeyUp = event => {
    const value = event.target.value
    setOrderNote(value)
    addOrderNote(client, checkout.id, value)
  }

  useEffect(() => setOverlayVisibility(true), [setOverlayVisibility])

  useEffect(() => () => setOverlayVisibility(false), [setOverlayVisibility])

  return (
    <div className="basket">
      <div className="close-basket" onClick={handleClose}>
        <CloseSVG />
      </div>
      {quantity > 0 ? (
        <>
          <div>{quantity} {t.basket.products_in_the_basket}</div>
          <div className="line-items-wrapper">
            <ul className="line-items">{line_items}</ul>
          </div>
        </>
      ) : (
        <div className="co--light">{t.basket.no_products}.</div>
      )}
      <div className="checkout-details">
        <ul className="totals fs--s co--light">
          <li className="mb--s">
            <span className="fs--s mb--s">{t.basket.comment}</span>
            <textarea
              placeholder={t.basket.comment_placeholder}
              rows="3"
              value={orderNote}
              onChange={handleKeyUp}
            />
          </li>
          <li className="mb--s">
            <span>Subtotal</span>
            <span>DKK {parseInt(checkout?.subtotalPrice?.amount)}</span>
          </li>
          <li className="mb--s">
            <span>{t.basket.vat}</span>
            <span>DKK {checkout?.totalTax?.amount}</span>
          </li>
          <li>
            <span>Total</span>
            <span>DKK {parseInt(checkout?.totalPrice?.amount)}</span>
          </li>
        </ul>
        <div onClick={handleCheckout}>
          <Button
            text={t.basket.checkout}
            classList="fs--base primary icon-right"
            icon="chevron-right"
          />
        </div>
      </div>
    </div>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => Basket.handleClickOutside,
}

export default onClickOutside(Basket, clickOutsideConfig)
