module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"anddrape","defaultLang":"da-dk","langs":["da-dk","en-eu"],"path":"/preview","previews":true,"omitPrismicScript":true,"pages":[{"type":"Homepage","match":"/:lang?","path":"/homepage","previews":true,"component":"/opt/build/repo/src/templates/homepage.js","sortBy":"title_ASC"},{"type":"Page","match":"/:lang?/:uid","path":"/page","previews":true,"component":"/opt/build/repo/src/templates/page.js","sortBy":"title_ASC"},{"type":"Inspiration","match":"/:lang?/inspiration","path":"/inspiration","previews":true,"component":"/opt/build/repo/src/templates/inspiration.js","sortBy":"title_ASC"},{"type":"Shop","match":"/:lang?/:uid","path":"/shop","previews":true,"component":"/opt/build/repo/src/templates/shop.js","sortBy":"title_ASC"},{"type":"Collection","match":"/:lang?/shop/:uid","path":"/collection","previews":true,"component":"/opt/build/repo/src/templates/collection.js","sortBy":"title_ASC"},{"type":"Case","match":"/:lang?/inspiration/:uid","path":"/case","previews":true,"component":"/opt/build/repo/src/templates/case.js","sortBy":"title_ASC"},{"type":"Category","match":"/:lang?/kategori/:uid","path":"/category","previews":true,"component":"/opt/build/repo/src/templates/category.js","sortBy":"title_ASC"},{"type":"Samples","match":"/:lang?/:uid","path":"/samples","previews":true,"component":"/opt/build/repo/src/templates/samples.js","sortBy":"title_ASC"},{"type":"Guide","match":"/:lang?/:uid","path":"/guide","previews":true,"component":"/opt/build/repo/src/templates/guide.js","sortBy":"title_ASC"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"brn0ijn"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"&Drape","short_name":"&Drape","icon":"src/images/favicon.png","start_url":"/","background_color":"#FFF","theme_color":"#333","display":"standalone","cache_busting_mode":"query"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-crisp-chat/gatsby-browser.js'),
      options: {"plugins":[],"websiteId":"3f08efd7-55f2-45c7-8205-da2ab7b288a7","enableDuringDevelop":true,"defer":true,"enableImprovedAccessibility":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WLRBMW6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
