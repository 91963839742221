// const defaultLang = 'da-dk'
// import { defaultLang } from "../../prismic-configuration";
const { defaultLang } = require("../../prismic-configuration");

module.exports = {
  linkResolver(doc) {
    if (
        doc.type === 'page' || 
        doc.type === 'shop' || 
        doc.type === 'inspiration' || 
        doc.type === 'samples' ||
        doc.type === 'guide'
      ) {
      return (doc.lang === defaultLang || !doc.lang) ? `/${doc.uid}/` : `/${doc.lang}/${doc.uid}`;
    }
    if (doc.type === 'case') {
      return (doc.lang === defaultLang || !doc.lang) ? `/inspiration/${doc.uid}/` : `/${doc.lang}/inspiration/${doc.uid}/`;
    }
    if (doc.type === 'category') {
      return (doc.lang === defaultLang || !doc.lang) ? `/kategori/${doc.uid}/` : `/${doc.lang}/kategori/${doc.uid}/`;
    }
    if (doc.type === 'collection') {
      return (doc.lang === defaultLang || !doc.lang) ? `/shop/${doc.uid}/`: `${doc.lang}/shop/${doc.uid}/`;
    }
    if (doc.type === 'homepage') {
      return (doc.lang === defaultLang || !doc.lang) ? `/` : `/${doc.lang}/`
    }
    else return '/';
  },
};
