/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('./src/styles/global.scss')

const { registerLinkResolver } = require('gatsby-source-prismic-graphql');
registerLinkResolver(require('./src/prismic/linkResolver').linkResolver);

exports.onRouteUpdate = ({ location, prevLocation }) => {
  const slices = document.getElementsByClassName("slice");
  Array.prototype.forEach.call(slices, function(slice, index) {
    slice.classList.remove('even')
    if(index % 2 === 0){
      slice.classList.add('even')
    }
  });
}

exports.onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await require(`intersection-observer`);
  }
}