import React from 'react'
import Client from 'shopify-buy'

const client = Client.buildClient({
  storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN,
  domain: `${process.env.SHOP_NAME}.myshopify.com`,
})

export const defaultStoreContext = {
  client,
  adding: false,
  checkout: { lineItems: [] },
  products: [],
  shop: {},
  basketVisible: false,
  overlayVisible: false,
  modalVisible: false,
  pushNavVisible: false,
  samplesAdded: [],
  modalContent:{
    title: null,
    text: null,
    image: null
  },
  addVariantToCart: () => {},
  addOrderNote: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  addLineItemsToCart: () => {},
  setPushNavVisibility: () => {},
  setBasketVisibility: () => {},
  setOverlayVisibility: () => {},
  setModalVisibility: () => {},
  setModalContent: () => {}
}

const StoreContext = React.createContext(defaultStoreContext)

export default StoreContext