import React from 'react'
import ChevronLeftSVG from '../images/chevron-left.inline.svg'
import ChevronRightSVG from '../images/chevron-right.inline.svg'
import BasketSVG from '../images/basket.inline.svg'
import SpinnerSVG from '../images/spinner.inline.svg'
import InstagramSVG from '../images/instagram.inline.svg'
import ExternalSVG from '../images/arrow-up-right.inline.svg'

const Button = (props) => {
  const {text, icon, classList} = props

  return(
    <div className={`button fs--s ${classList}`}>
      {text &&
      <div className={`button-text`}>{text}</div>
      }
      {icon &&
        <div className="button-icon">
          {icon === 'basket' &&
            <BasketSVG />
          }
          {icon === 'chevron-left' &&
            <ChevronLeftSVG />
          }
          {icon === 'chevron-right' &&
            <ChevronRightSVG />
          }
          {icon === 'spinner' &&
            <SpinnerSVG />
          }
          {icon === 'instagram' &&
            <InstagramSVG />
          }
          {icon === 'external' &&
            <ExternalSVG />
          }
        </div>
      }
    </div>
  )
}

export default Button