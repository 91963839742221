import React from "react"
import InternalLink from "./InternalLink"
import get from "lodash/get"
import ExternalSVG from "../images/arrow-up-right.inline.svg"
import { Link } from "gatsby"
import getTranslations from "../translations"
import { defaultLang } from "../../prismic-configuration"

const SecondaryNav = props => {
  let { links, lang } = props
  const t = getTranslations(lang)

  return (
    <ul className="nav secondary-nav">
      {links.map((node, i) => {
        const link = get(node, "secondary_link")
        const title = get(link, "title[0].text")
        const uid = get(link, "_meta.uid")
        const type = get(link, "_meta.type")
        const lang = get(link, "_meta.lang")

        return (
          <li key={i}>
            <InternalLink uid={uid} type={type} title={title} lang={lang}>
              {title}
            </InternalLink>
          </li>
        )
      })}
      <li>
        <Link to={lang === defaultLang || !lang ? "/stylerad" : lang + "/style-advice"}>{t.secondary_nav.contact}</Link>
      </li>
      <li>
        <Link to={lang === defaultLang || !lang ? "/joinus" : lang + "/joinus"}>Join us</Link>
      </li>

      <li>
        <a target="_blank" href="https://bit.ly/anddrapeimages">
          {t.menu.image_bank} <ExternalSVG />
        </a>
      </li>
      <li>
        <a target="_blank" href="https://www.instagram.com/anddrape/">
          Instagram <ExternalSVG />
        </a>
      </li>
    </ul>
  )
}

export default SecondaryNav
