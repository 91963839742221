import React from 'react'
import { Link } from "gatsby"
import LogoSVG from '../images/logo.inline.svg'
import { defaultLang } from '../../prismic-configuration'

const Logo = (props) => {
  const { lang } = props
  return(
    <div className="logo">
      <Link to={lang === defaultLang ? '/' : '/' + lang} ><LogoSVG /></Link>
    </div>
  )
}

export default Logo