import React, { useContext, useState } from "react"
import StoreContext from "../context/StoreContext"
import ProductImg from "./ProductImage"
import get from "lodash/get"
import CloseSVG from "../images/close.inline.svg"
import SpinnerSVG from "../images/spinner.inline.svg"

const LineItem = props => {
  const { line_item } = props
  const [removing, setRemoving] = useState(false)
  const variantImage = get(line_item, "variant.image")
  let imgThumbSrc = false
  if (variantImage) {
    imgThumbSrc = variantImage.src.replace(/(\.[^\.]*)?$/, "_small$1")
  }

  const { removeLineItem, client, checkout } = useContext(StoreContext)

  const handleRemove = () => {
    removeLineItem(client, checkout.id, line_item.id)
    setRemoving(true)
  }

  return (
    <div className={`card line-item ${removing && "removing"}`}>
      <div className="line-item-inner">
        {imgThumbSrc && (
          <ProductImg
            src={imgThumbSrc}
            title={`${line_item.title} product shot`}
          />
        )}
        <ul className="line-item-details">
          <li>
            <span className="fs--s">
              {line_item.quantity} × {line_item.title}
            </span>
          </li>
          {line_item.customAttributes.length > 0 && (
            <li>
              <span className="co--light fs--xs">
                {line_item.customAttributes[0].value}
              </span>
            </li>
          )}
          <li>
            <span className="co--light fs--xs">
              DKK {parseInt(line_item.variant.price?.amount)}
            </span>
          </li>
        </ul>
      </div>
      <div
        className={`trigger-close ${removing && "spinner"}`}
        onClick={handleRemove}
      >
        {removing ? <SpinnerSVG /> : <CloseSVG />}
      </div>
    </div>
  )
}

export default LineItem
