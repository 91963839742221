import React, {useEffect, useState} from 'react'
import 'intersection-observer'
import { useInView } from 'react-intersection-observer'
import get from 'lodash/get'

const THRESHOLD = [1]

const Img = (props) => {
  const {src, alt, prismicImage, lazyLoad} = props
  const [loaded, setLoaded] = useState(null)
  const [ref, inView] = useInView({
    triggerOnce:true,
  })

    return(
      <div className="custom-image-wrapper" ref={ref}>
        {inView &&
          <picture>
            {get(prismicImage,'url') &&
              <source
              media="(min-width: 1920px)"
              srcSet={get(prismicImage,'url')}
              />
            }
            {get(prismicImage,'desktop.url') &&
              <source
              media="(min-width: 1280px)"
              srcSet={get(prismicImage,'desktop.url')}
              />
            }
            {get(prismicImage,'tablet.url') &&
              <source
              media="(min-width: 415px)"
              srcSet={get(prismicImage,'tablet.url')}
              />
            }
            {get(prismicImage,'phone.url') &&
              <source srcSet={get(prismicImage,'phone.url')} />
            }
            <img
                src={prismicImage ? get(prismicImage,'phone.url') : src}
                alt={prismicImage && !alt ? get(prismicImage,'alt') : alt}
                className={loaded ? null : 'loading'}
                onLoad={()=>{setLoaded(true)}}
            />
          </picture>
        }
      </div>
    )
}

export default Img