import Button from '../components/Button'

import React from 'react'
const TeaserTextOverlay = (props) => {
  const {heading, cta, smallText, extraSmallText} = props

  return(
    <div className="teaser-text-overlay">
      {smallText ?
      <p className="lh--m">{heading}</p>
      : extraSmallText ?
      <p className="fs--xs lh--m">{heading}</p>
    :
      <p className="fs--m lh--s">{heading}</p>
    }
    {!extraSmallText &&
      <Button classList="light" text={cta} icon="chevron-right"/>
    }
    </div>
  )
}

export default TeaserTextOverlay