import React, {useContext} from 'react'
import StoreContext from '../context/StoreContext'

const Navicon = (props) => {
  const context = useContext(StoreContext)
  const {setPushNavVisibility, pushNavVisible} = context
  const handleOnClick = (event) => {
    setPushNavVisibility(!pushNavVisible)
  }
  return(
    <div onClick={handleOnClick} className={pushNavVisible ? 'navicon active' : 'navicon'}>
      <div className="line"></div>
      <div className="line"></div>
      <div className="line"></div>
    </div>
  )
}

export default Navicon