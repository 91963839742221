import React from 'react'
import get from 'lodash/get'
import KlarnaSVG from '../images/klarna.inline.svg'
import PayPalSVG from '../images/paypal.inline.svg'
import VisaSVG from '../images/visa.inline.svg'
import MasterCardSVG from '../images/mastercard.inline.svg'
import MobilePaySVG from '../images/mobilepay.inline.svg'

const renderLogo = (title) => {
  switch(title.toLowerCase()) {
    case 'klarna':
      return <KlarnaSVG alt={title} />
    case 'paypal':
      return <PayPalSVG alt={title} />
    case 'visa':
      return <VisaSVG alt={title} />
    case 'mastercard':
      return <MasterCardSVG alt={title} />
    case 'mobilepay':
      return <MobilePaySVG alt={title} />
    default:
      return title
  }
}

const PaymentOptions = (props) =>{
  const { links } = props
  return(
    <ul className="payment-options">
      { links.map((link, i) => {
        const title = get(link, 'payment_option_title[0].text')
        const url = get(link, 'payment_option_link.url')
        return <li key={i}><a href={url} target="_blank" title={title} rel="noopener noreferrer">{renderLogo(title)}</a></li>
      }) }
    </ul>
  )
}

export default PaymentOptions