import React from "react"
import { CSSTransition } from "react-transition-group"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import StoreContext, { defaultStoreContext } from "../context/StoreContext"
import Basket from "../components/Basket"
import Header from "../components/Header"
import Footer from "../components/Footer"
import ShopFooter from "../components/ShopFooter"
import Instafeed from "../components/Instafeed"
import Overlay from "../components/Overlay"
import Modal from "../components/Modal"
import PushNav from "../components/PushNav"
import { defaultLang, langs } from "../../prismic-configuration"

export const layoutQuery = graphql`
  query SiteTitleQuery($lang: String) {
    site {
      siteMetadata {
        title
      }
    }
    prismic {
      allLayouts(lang: $lang) {
        edges {
          node {
            address
            instagram_cta
            instagram_handle
            payment_options {
              payment_option_title
              payment_option_link {
                ... on PRISMIC__ExternalLink {
                  _linkType
                  url
                }
              }
            }
            vat_identification_number
            tertiary_navigation {
              tertiary_link {
                ... on PRISMIC_Shop {
                  title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_Page {
                  title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_Case {
                  title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_Samples {
                  title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_Samples {
                  title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_Inspiration {
                  title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
              }
            }
            secondary_navigation {
              secondary_link {
                ... on PRISMIC_Shop {
                  title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_Page {
                  title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_Case {
                  title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_Guide {
                  title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_Samples {
                  title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_Inspiration {
                  title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
              }
            }
            primary_navigation {
              link {
                ... on PRISMIC_Shop {
                  title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_Page {
                  title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_Case {
                  title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_Samples {
                  title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_Inspiration {
                  title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
              }
            }
            _meta {
              lang
            }
          }
        }
      }
    }
  }
`
class Layout extends React.Component {
  state = {
    store: {
      ...defaultStoreContext,
      setModalContent: (title = null, text = null, image = null) => {
        this.setState(state => ({
          store: {
            ...state.store,
            modalContent: {
              title: title ? title : null,
              text: text ? text : null,
              image: image ? image : null,
            },
          },
        }))
      },
      setModalVisibility: boolean => {
        this.setState(state => ({
          store: {
            ...state.store,
            modalVisible: boolean,
          },
        }))
      },
      setBasketVisibility: boolean => {
        this.setState(state => ({
          store: {
            ...state.store,
            basketVisible: boolean,
          },
        }))
      },
      setPushNavVisibility: boolean => {
        this.setState(state => ({
          store: {
            ...state.store,
            pushNavVisible: boolean,
          },
        }))
        // if(boolean){
        //   disableBodyScroll(document.body)
        // }else{
        //   enableBodyScroll(document.body)
        // }
      },
      setOverlayVisibility: boolean => {
        this.setState(state => ({
          store: {
            ...state.store,
            overlayVisible: boolean,
          },
        }))
        if (boolean) {
          //disableBodyScroll(document.body)
        } else {
          //enableBodyScroll(document.body)
        }
      },
      addVariantToCart: (variantId, quantity) => {
        if (variantId === "" || !quantity) {
          console.error("Both a size and quantity are required.")
          return
        }

        this.setState(state => ({
          store: {
            ...state.store,
            adding: true,
          },
        }))

        const { checkout, client } = this.state.store
        const checkoutId = checkout.id
        const lineItemsToUpdate = [
          { variantId, quantity: parseInt(quantity, 10) },
        ]

        return client.checkout
          .addLineItems(checkoutId, lineItemsToUpdate)
          .then(checkout => {
            this.setState(state => ({
              store: {
                ...state.store,
                checkout,
                adding: false,
              },
            }))
          })
      },
      addLineItemsToCart: lineItemsToUpdate => {
        this.setState(state => ({
          store: {
            ...state.store,
            adding: true,
          },
        }))

        const { checkout, client } = this.state.store
        const checkoutId = checkout.id

        return client.checkout
          .addLineItems(checkoutId, lineItemsToUpdate)
          .then(checkout => {
            this.setState(state => ({
              store: {
                ...state.store,
                checkout,
                adding: false,
                basketVisible: true,
              },
            }))
          })
      },
      removeLineItem: (client, checkoutID, lineItemID) => {
        this.setState(state => ({
          store: {
            ...state.store,
            adding: true,
          },
        }))
        return client.checkout
          .removeLineItems(checkoutID, [lineItemID])
          .then(res => {
            this.setState(state => ({
              store: {
                ...state.store,
                checkout: res,
                adding: false,
              },
            }))
          })
      },
      addOrderNote: (client, checkoutID, value) => {
        console.log(client, checkoutID, value)
        const input = {note: value};

        this.setState(state => ({
          store: {
            ...state.store,
            adding: true,
          },
        }))

        return client.checkout
          .updateAttributes(checkoutID, input)
          .then(res => {
            this.setState(state => ({
              store: {
                ...state.store,
                checkout: res,
                adding: false,
              },
            }))
          })
      },
      updateLineItem: (client, checkoutID, lineItemID, quantity) => {
        const lineItemsToUpdate = [
          { id: lineItemID, quantity: parseInt(quantity, 10) },
        ]

        return client.checkout
          .updateLineItems(checkoutID, lineItemsToUpdate)
          .then(res => {
            this.setState(state => ({
              store: {
                ...state.store,
                checkout: res,
              },
            }))
          })
      },
    },
  }

  async initializeCheckout() {
    // Check for an existing cart.
    const isBrowser = typeof window !== "undefined"
    const existingCheckoutID = isBrowser
      ? localStorage.getItem("shopify_checkout_id")
      : null

    const setCheckoutInState = checkout => {
      if (isBrowser) {
        localStorage.setItem("shopify_checkout_id", checkout.id)
      }

      this.setState(state => ({
        store: {
          ...state.store,
          checkout,
        },
      }))
    }

    const createNewCheckout = () => this.state.store.client.checkout.create()
    const fetchCheckout = id => this.state.store.client.checkout.fetch(id)

    if (existingCheckoutID) {
      try {
        const checkout = await fetchCheckout(existingCheckoutID)
        console.log("🚀 ~ checkout", checkout)

        // Make sure this cart hasn’t already been purchased.
        if (!checkout.completedAt) {
          setCheckoutInState(checkout)
          return
        }
      } catch (e) {
        localStorage.setItem("shopify_checkout_id", null)
      }
    }

    const newCheckout = await createNewCheckout()
    setCheckoutInState(newCheckout)
  }

  componentDidMount() {
    this.initializeCheckout()
  }

  render() {
    let { children, pageContext: { alternateLanguages, lang = defaultLang } } = this.props


    return (
      <StoreContext.Provider value={this.state.store}>
        <StaticQuery
          query={layoutQuery}
          render={data => {
            let prismicLayoutData = data.prismic.allLayouts.edges.find(layout => {
              return layout.node._meta.lang === lang
            })
            if (prismicLayoutData) {
              prismicLayoutData = prismicLayoutData.node
            }

            const primaryNav = prismicLayoutData.primary_navigation
            const secondaryNav = prismicLayoutData.secondary_navigation
            const tertiaryNav = prismicLayoutData.tertiary_navigation
            const address = prismicLayoutData.address
            const vat = prismicLayoutData.vat_identification_number
            const paymentOptions = prismicLayoutData.payment_options

            const instagramCTA = prismicLayoutData.instagram_cta
            const instagramHandle = prismicLayoutData.instagram_handle

            return (
              <div className="app">
                <div className="loader"></div>

                <CSSTransition
                  in={this.state.store.basketVisible}
                  timeout={500}
                  classNames="basket"
                  unmountOnExit
                >
                  <Basket lang={lang} />
                </CSSTransition>

                {prismicLayoutData && primaryNav && (
                  <Header primaryNav={primaryNav} lang={lang} altLang={alternateLanguages} />
                )}
                <main className="main">{children}</main>

                {instagramCTA && instagramHandle && (
                  <Instafeed
                    instagramCTA={instagramCTA}
                    instagramHandle={instagramHandle}
                    lang={lang}
                  />
                )}

                {prismicLayoutData && secondaryNav && address && vat && (
                  <Footer
                    secondaryNav={secondaryNav}
                    address={address}
                    vat={vat}
                    lang={lang}
                  />
                )}

                {prismicLayoutData && tertiaryNav && (
                  <ShopFooter
                    tertiaryNav={tertiaryNav}
                    paymentOptions={paymentOptions}
                    lang={lang}
                    altLang={alternateLanguages}
                  />
                )}
                <CSSTransition
                  in={this.state.store.overlayVisible}
                  timeout={500}
                  classNames="overlay"
                  unmountOnExit
                >
                  <Overlay />
                </CSSTransition>

                <CSSTransition
                  in={this.state.store.modalVisible}
                  timeout={500}
                  classNames="modal"
                  unmountOnExit
                >
                  <Modal />
                </CSSTransition>

                <CSSTransition
                  in={this.state.store.pushNavVisible}
                  timeout={500}
                  classNames="push-nav"
                  unmountOnExit
                >
                  <PushNav
                    primaryNav={primaryNav}
                    secondaryNav={secondaryNav}
                    lang={lang}
                  />
                </CSSTransition>
              </div>
            )
          }}
        />
      </StoreContext.Provider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.query = layoutQuery

export default Layout
