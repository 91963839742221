import React, {useContext, useEffect} from 'react'
import StoreContext from '../context/StoreContext'
import Wrapper from './Wrapper'
import ModalContent from './ModalContent'

const Modal = (props) => {
  const context = useContext(StoreContext)
  const {modalContent, setModalVisibility, setOverlayVisibility, setModalContent} = context
  const {title, text} = modalContent
  const handleClose = () => {
    setModalVisibility(false)
    setModalContent()
  }

  useEffect( () => setOverlayVisibility(true), [setOverlayVisibility] );

  useEffect( () => () => setOverlayVisibility(false), [setOverlayVisibility] );

  return(
    <div className="modal">
      <Wrapper>
        <ModalContent title={title} text={text} handleClose={handleClose} />
      </Wrapper>
    </div>
  )
}

export default Modal