import React, {useContext} from 'react'
import InternalLink from './InternalLink'
import BasketSVG from '../images/basket.inline.svg'
import SpinnerSVG from '../images/spinner.inline.svg'
import get from 'lodash/get'
import countQuantity from '../utils/countQuantity'
import StoreContext from '../context/StoreContext'
import LanguageSwitcher from './LanguageSwitcher'

const PrimaryNav = (props) => {
  const context = useContext(StoreContext)
  const {setBasketVisibility, adding, checkout} = context
  const quantity = countQuantity(checkout.lineItems)
  let { links, altLang, lang } = props

  const handleClick = () =>{
    setBasketVisibility(true)
  }

  return(
    <nav className="primary-nav">
      <ul>
        { links.map((node, i) => {
          const link = get(node, 'link')
          const title = get(link, 'title[0].text')
          const uid = get(link, '_meta.uid')
          const type = get(link, '_meta.type')
          const lang = get(link, '_meta.lang')

          if(uid && type){
            return <li key={i}><InternalLink lang={lang} uid={uid} type={type} title={title}>{title}</InternalLink></li>
          }
        }) }
      </ul>
      <div className='menu-icons'>
        <LanguageSwitcher currentLang={lang} altLang={altLang} />
        <div className="basket-icon" onClick={handleClick}>
          <span className="fs--xs">{quantity}</span>
          {adding ?
            <SpinnerSVG />
            :
            <BasketSVG />
          }
        </div>
      </div>
    </nav>
  )
}

export default PrimaryNav